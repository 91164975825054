import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import {
	Box,
	Image,
	Content,
	Title,
	Description,
	SearchWrapper,
} from "./banner.style";

import { Waypoint } from "react-waypoint";
import { useAppDispatch } from "../../contexts/app/app.provider";
import Search from "../../features/search/search";
import bck from "../../assets/images/banner/grocery-alt.jpg";
import { background } from "styled-system";

// interface Props {
//   imageUrl: string;
//   intlTitleId: string;
//   intlDescriptionId: string;
// }

export const Banner = ({ imageUrl, intlTitleId, intlDescriptionId, onScroll }) => {
	const dispatch = useAppDispatch();
	const setSticky = useCallback(() => dispatch({ type: "SET_STICKY" }), [
		dispatch,
	]);
	const removeSticky = useCallback(() => dispatch({ type: "REMOVE_STICKY" }), [
		dispatch,
	]);
	const onWaypointPositionChange = ({ currentPosition }) => {
		if (!currentPosition || currentPosition === "above") {
			setSticky();
		}
	};
	return (
		<Box>
			<div
				style={{
					backgroundImage: `url(${bck})`,
					backgroundSize: "cover",
					width: "100%",
					height: "100%",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					position: "absolute",
					top: 0,
					left: 0,
					"@media (max-width: 990px) and (min-width: 768px)": {
						backgroundPosition: "inherit",
					},
				}}
			/>
			<Content>
				<Title>
					<FormattedMessage
						id={intlTitleId}
						defaultMessage="Set Your Title Through Language File"
						values={{ minute: 90 }}
					/>
				</Title>
				<Description>
					<FormattedMessage
						id={intlDescriptionId}
						defaultMessage="Set Your Description Through Language File"
					/>
				</Description>
				{/* <SearchWrapper>
					<Search
						className="banner-search"
						shadow="0 21px 36px rgba(0,0,0,0.05)"
						onScroll={onScroll}
					/>
				</SearchWrapper> */}
				<Waypoint
					onEnter={removeSticky}
					onLeave={setSticky}
					onPositionChange={onWaypointPositionChange}
				/>
			</Content>
		</Box>
	);
};
