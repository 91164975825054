import React, { useEffect, useState, useRef } from "react";
import { Modal } from "@redq/reuse-modal";
import Carousel from "../components/carousel/carousel";
import { Banner } from "../components/banner/banner";
import { withNavbar } from "../hocs/withNavbar";
import {
	MainContentArea,
	SidebarSection,
	ContentSection,
	OfferSection,
	MobileCarouselDropdown,
} from "../assets/styles/pages.style";
import Footer from "../layouts/footer-main";

import { siteOffers } from "../site-settings/site-offers";
import { sitePages } from "../site-settings/site-pages";
import { useRefScroll } from "../utils/use-ref-scroll";
import { CATEGORY_MENU_ITEMS } from "../site-settings/site-navigation";
import Sidebar from "../layouts/sidebar/sidebar";
import Products from "../components/product-grid/product-list/product-list";
import CartPopUp from "../features/carts/cart-popup";
import BackMenu from "../features/principal-menu/principal-menu";
import axios from "axios";
import mobilenora from '../assets/images/mobilenora2.png'

const getDeviceType = () => {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		const deviceType = {
			mobile: false,
			tablet: true,
			desktop: false,
		};
		return deviceType;
	}
	if (
		/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua
		)
	) {
		const deviceType = {
			mobile: true,
			tablet: false,
			desktop: false,
		};
		return deviceType;
	}
	const deviceType = {
		mobile: false,
		tablet: false,
		desktop: true,
	};
	return deviceType;
};

const CategoryPage = () => {
	const deviceType = getDeviceType();
	const [banners, setBanners] = useState([]);
	const scrollRef = useRef(null);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	const query = "";
	const { elRef: targetRef, scroll } = useRefScroll({
		percentOfElement: 0,
		percentOfContainer: 0,
		offsetPX: -110,
	});
	useEffect(() => {
		if (query.text || query.category) {
			scroll();
		}
	}, [query.text, query.category]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(`https://nora-rey-backend.herokuapp.com/api/banners/`);
				const { data } = res;
				if (data?.data?.length > 0) {
					setBanners(data?.data);
				} else {
					setBanners(siteOffers);
				}
			} catch (error) {
				setBanners(siteOffers);
				setError(error);
			}
			setLoading(false);
		};

		fetchData();
	}, []);

	const onScroll = () => {
		window.scrollTo({
			top:
				scrollRef.current.getBoundingClientRect().top + window.pageYOffset - 65,
			behavior: 'smooth',
		});
	}

	const PAGE_TYPE = "grocery";
	const page = sitePages[PAGE_TYPE];
	if (!page) return null;

	console.log(deviceType.desktop)

	return (
		<>
			{/* <Modal> */}
			{
				deviceType.desktop ? (
					<Banner
					onScroll={onScroll}
					intlTitleId={page.banner_title_id}
					intlDescriptionId={page.banner_description_id}
					imageUrl={page.banner_image_url}
				/>
				) : (
					<img src={mobilenora} />
				)
			}
			
				{/* <MobileCarouselDropdown>
					<Sidebar type={PAGE_TYPE} deviceType={deviceType} />
				</MobileCarouselDropdown>
				<OfferSection>
					<div style={{ margin: "0 -10px" }}>
						<Carousel deviceType={deviceType} data={banners} isForOffer />
					</div>
				</OfferSection>
				<MainContentArea >
					<SidebarSection>
						<Sidebar type={PAGE_TYPE} deviceType={deviceType} />
					</SidebarSection>
					<ContentSection ref={scrollRef}>
						<div ref={targetRef}>
							<Products
								type={PAGE_TYPE}
								deviceType={deviceType}
								fetchLimit={20}
							/>
						</div>
					</ContentSection>
				</MainContentArea> */}
				{/* <CartPopUp deviceType={deviceType} />
				<BackMenu deviceType={deviceType} /> */}
			{/* </Modal> */}
			<Footer />
		</>
	);
};

export default CategoryPage;
